import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col
            lg="2"
            md="2"
            sm="12"
            className="wow fadeInUp span12 offcanlink"
            data-wow-duration="0.5s"
          >
            <img
              src="images/05993dfa7132ab1ad50099b06767aa50_1200_80.webp"
              alt="logo"
            />
          </Col>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInUp servicesFooter"
            data-wow-duration="0.5s"
          >
            <h5>Services</h5>
            <div>

              <Link to="/BusinessAdministration">Business Administration </Link>
              <Link to="/SoftwareDevelopment">Software Development</Link>
              <Link to="/CustomerSupport">Customer Support </Link>
              <Link to="/SalesSolutions">Automated Sales </Link>
              <Link to="/Marketing">Digital Marketing</Link>
              <Link to="/CreativeServices">Creative Services</Link>
            </div>
          </Col>
          <Col
            lg="5"
            md="5"
            sm="12"
            className="wow fadeInUp span12 emailFooter"
            data-wow-duration="0.5s"
          >
            <h5>EMAIL US</h5>
            <a href="mailto:Support@extronnect.com">
            <p>Support@extronnect.com </p>
            </a>
            <p style={{ marginTop: "80px" }}>
              3550 Moller Rd, Indianapolis, IN 46224, USA
            </p>
          </Col>
          <Col
            lg="2"
            md="2"
            sm="12"
            className="wow fadeInUp span12 callFooter"
            data-wow-duration="0.5s"
          >
            <h5>CALL US</h5>
            <div>
              <a href="tel:+1 (786) 485-0848 ">
                Sales Team:
                <br />
                <span>+1 (786) 485-0848</span>
              </a>
              <br />

              <a href="tel:+1 (786) 485-0848">
                Support Team:
                <br />
                <span> +1 (310) 601-8347</span>
              </a>
            </div>
          </Col>
        </Row>
        <div className="iconsfooter wow fadeInUp ">
          <a href="https://www.facebook.com/people/Extronnect/61557772413907/">
            <img src="images/socialmediaicons/fb.png" alt="fb" />
          </a>
          <a href="https://www.instagram.com/extronnect/">
            <img src="images/socialmediaicons/insta.png" alt="insta" />
          </a>

          <a href="https://x.com/extronnect">
            <img src="images/socialmediaicons/twitter.png" alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/extronnect/">
            <img src="images/socialmediaicons/linkedin.png" alt="linked in" />
          </a>
        </div>
        <div className="footerBorder">
          <div className="copyRight wow fadeInUp ">
            <p>Copyright © 2025 Extronnect LLC. All Rights Reserved.</p>
            <div>
              <Link to="/terms-and-conditions" >
                Terms and Conditions
              </Link>
              <Link to="/policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
