import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import { db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Preloader = () => (
  <div class="loader"></div>
);
function FormComponent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US");
    const year = new Date(formattedDate).getFullYear();
    const month = (new Date(formattedDate).getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = new Date(formattedDate).getDate().toString().padStart(2, "0");

    const dateWithoutAmPm = formattedDate.slice(0, -3);
    const amPm = formattedDate.slice(-3);
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;
    const docName = `${day}_${month}_${year}`;

    if (selectedFile) {
      const storageRef = ref(
        storage,
        `/files/${formattedDateWithMilliseconds.replaceAll("/", "_") + selectedFile.name
        }`
      );
      try {
        await uploadBytes(storageRef, selectedFile);
        const url = await getDownloadURL(storageRef);
        formData["fileUrl"] = url;
      } catch (error) {
        console.error(error);
      }
    }

    const uid = formattedDateWithMilliseconds
      .replaceAll("/", "_")
      .replaceAll(":", "-")
      .replace(", ", "@")
      .replace(" ", "#");

    const submitData = {
      [uid]: formData,
    };

    await setDoc(doc(db, "inquiry", docName), submitData, {
      merge: true,
    });
    const emailHtml = `Hey ${formData.name},<br/>We have received your inquiry submitted via our website. Our team is on it and will get back to you as soon as possible. If you have any questions in the meantime, feel free to reach out by replying to this email.<br/><br/>Thank you,<br/><br/>`;
    const signatureHtml = `  <font color="#888888">
    <span class="gmail_signature_prefix">-- </span><br>
    <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
        <div dir="ltr">
            <table class="signature-table" width="414" cellpadding="0" cellspacing="0" border="0">
                <tbody>
                    <tr>
                        <td valign="top" class="signature-cell">
                            <table border="0" cellpadding="0" cellspacing="0" width="414" class="inner-table">
                                <tbody>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="name-title"><font face="arial black, sans-serif">Samir H</font></span>
                                        </td>
                                        <td class="no-padding"></td>
                                    </tr>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="position-title"><font face="arial black, sans-serif">Client Success Manager<br><br></font></span>
                                        </td>
                                        <td align="right" width="170" class="no-padding">
                                            <p dir="ltr" class="social-links">
                                                <span>
                                                    <a href="http://extronnect.com/" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/ERQye9fHlkGU5k3qeAOaIPlXt6WEXXaU3Qk6sX-RPc989aGEznY7hiAxlQrZZy4jbAFXGrMQpBM7XooSYiCJQoTXjmwx2feUNpI2IMZAFp4rcsQCUmyJTDeeYB7wXVKx2ajwEOROluc4SDSAovfxy44" width="23" height="23" alt="Website" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://www.instagram.com/extronnect/" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/RBHXzA6EarsjkC28LnCKIOX46gSouRWQvToQulLKq_T8amnmvJz3GO1Pd5GML1bps41dLRSlJe8y3UR6olRNRWBBzflLsAPtb2KI9n6PynABhc6WXrH3CrSimE6b-7cn4ntkAr6EnTkr2i0Qw9QQr0w" width="23" height="23" alt="Instagram" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://wa.me/15619225376" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/Rgf8cTDfganYaSmVeRG8an1XXfBmeubjqi1eCPjKsprqPPgN6A-isjGF-TvzNNacha4ut_sMzFYXo3DbOEMb9SIcp4vA1kKnvykcTww8iV_wh2L6GsyVb9aTQiZO5e7m-2hESYGqdtbnOFF8WJBWuUg" width="23" height="23" alt="WhatsApp" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://www.linkedin.com/company/100955777" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/0554XXBDxwmR3G1JA7ijlqWufEnXfK_l1LUpuqg-eydQVjai3t0GXbVp1xrqfvKH1g1thL1EzqjUpMqvwITbTb7ndmCv1125NqDuBN45wsCQkCB17bxXIpAEfbCMv_G2WQwpAqFp6vIBmNo3uqgzKMI" width="23" height="23" alt="LinkedIn" class="icon">
                                                    </a>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="contact-info">
                                                <span class="icon-cell">
                                                    <img src="https://lh7-us.googleusercontent.com/qv5uavYFMPAfRw6bWUk8GYOV-ngLGdu4ndYfyql8dq8Cogw2Jfgm4IZhbUIyj50D0kNLCA-J8mF_YCkw5RrKm-6p3cQog0MTiVkR6WE_EK5fcMgLP9ufvtzS_N87-tNRUJuucuG9sNvShAplf9oDnW4" width="19" height="19" class="contact-icon">
                                                </span>
                                                <font face="arial black, sans-serif">(310) 601-8347</font>
                                                <br>
                                                <span class="icon-cell">
                                                    <img src="https://lh7-us.googleusercontent.com/d6sEan4jectEq-6hIecRokXTA4nHSmFDVk0v71K5EXwxoepvfnMyxoNfUhrrTlVOLbssr81WHwRaBO-IhvFHOrD2vkl0gsB7nmxPznFc_YGxFTRiwwvhQMgxlr4i3mPJ2gz9SSNdsbZFLQMRs0JZzQo" width="19" height="19" class="contact-icon">
                                                </span>&nbsp;
                                                <a href="mailto:support@extronnect.com" class="email-link">Support@extronnect.com</a>&nbsp; &nbsp; &nbsp;
                                            </span>
                                        </td>
                                        <td valign="bottom" align="right" class="no-padding"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td class="banner-cell">
                            <a href="https://extronnect.com/" target="_blank">
                                <img border="0" alt="Banner" width="414" src="https://ci3.googleusercontent.com/meips/ADKq_Nao5PJGW-cu5EO2By_8Ke9Dcl1rL90Pfc7dZ7nzBIH9qx9rk_HYSLsB2oqY6rLLWq_UIJ-n=s0-d-e1-ft#https://i.imgur.com/u495xLU.png" class="banner-image">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="disclaimer-cell">
                            The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</font>`;
    const fullHtml = emailHtml + signatureHtml;

    addDoc(collection(db, "mail"), {
      to: formData.email,
      message: {
        subject: "Extronnect | We Have Received Your Inquiry!",
        html: fullHtml,
      },
    });
    await addDoc(collection(db, "mail"), {
      to: "Support@extronnect.com",
      message: {
        subject: "New Inquiry Submitted",
        html: `<code><h1>New Inquiry</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Phone: ${formData.phone}</h2><h2>Company: ${formData.company}</h2><h2>Company URL: ${formData.companyUrl}</h2><h2>Inquiry: ${formData.inquiry}</h2><h2>File Url: ${formData.fileUrl}</h2></code>`,
      },
    });

    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleNewInquiry = () => {
    setIsSubmitted(false);
    setFormData({});
    setSelectedFile(null);
  };

  return (
    <section className="contactsec2" id="Inquiry">
      <Container>
        <h2 className="wow fadeInUp">Submit An Inquiry</h2>
        <Row className="align-items-center">
          <Col md="6" sm="12">
            <div className="slideInLeft wow">
              <div className="contactform">
                {!isSubmitted ? (
                  <>
                    <h3>Ready to take the next step?</h3>

                    <form onSubmit={handleSubmit} id="contactForm">

                      <div className="row justify-content-between">
                        <div className="col-md-6">
                          <p>Full Name *</p>
                          <input
                            type="text"
                            name="name"
                            placeholder="Full Name *"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <p> Email * </p>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email *"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <p>Phone</p>
                          {/* <input
                          type="tel"
                          name="phone"
                          placeholder="Phone *"
                          required
                          onChange={handleChange}
                        /> */}
                          <PhoneInput
                            country={"us"}
                            inputProps={{
                              name: 'phone',
                            }}
                            inputClass="inputPhone"
                            placeholder="Phone"
                            onChange={(value) => handleChange({ target: { name: 'phone', value } })}
                          />

                        </div>

                        <div className="col-md-6">
                          <p>Company Name </p>
                          <input
                            type="text"
                            name="company"
                            placeholder="Company Name"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <p>Company URL (Optional)</p>
                          <input
                            type="text"
                            name="companyUrl"
                            placeholder="Company URL"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div>
                        <p>Inquiry Details *</p>
                        <input
                          type="text"
                          name="inquiry"
                          style={{ height: "60px" }}
                          className="inquiry"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p>Optional Attachment </p>
                        <div>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="fileInput" className="custom-button">
                            <FiUpload />
                            Upload Your Doc
                            {selectedFile && (
                              <div className="file-name">
                                Selected file: {selectedFile.name}
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="checkDiv">
                        <input type="checkbox" 
                                 name="recieve-sms"
                          
                                 onChange={handleChange}
                                 />
                        <label>

                          I Consent to Receive SMS Notifications, Alerts from Extronnect.
                          Message frequency varies. Message & data rates may apply. Text HELP to (786) 485-0848 for assistance. You can reply STOP to unsubscribe at any time.
                        </label>
                      </div>
                      {/* <input type="submit" value="Submit" disabled={isSubmitting} /> */}
                      <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? <Preloader /> : "Submit"}
                      </button>
                      <div className="terms-privacy">
                        <Link to="/terms-and-conditions">
                          <span>Terms & conditions</span>
                        </Link>
                        <p> |  </p>
                        <Link to="/policy">
                          <span>Privacy Policy</span>
                        </Link>

                      </div>
                    </form>
                  </>
                ) : (
                  <div className="thanksInquiry">
                    <h3>
                      Thank you for your inquiry! Our team will get back to you
                      shortly.
                    </h3>
                    <button onClick={handleNewInquiry}>Submit Another Inquiry</button>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md="6" sm="12">
            <div className="slideInRight wow">
              <img src="images/vectors/Inquiry.png" alt="Inquiry" />
              <div>
                <div className="mt-5 paraForm">
                  <p>
                    Submit an inquiry to <span>Extronnect </span> today and let
                    us help you achieve your business goals. Our team of experts
                    is here to provide personalized solutions tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FormComponent;
