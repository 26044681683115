import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";

function Policy() {
    return (
        <>
            <div>
                <Helmet>
                    <title>Extronnect | Terms - Legal Guidelines and Policies   </title>
                </Helmet>
                <ScrollToTop />

                <section className="policy" >
                    <div>
                        <div>
                            <div>

                                <div id="preview-container" className="preview-container hl_page-preview--content">
                                    <div>

                                        <div className="fullSection none noBorder radius0 none c-section c-wrapper section-YYZWRoGRVH">

                                            <div className="c-heading c-wrapper">

                                                <div>
                                                    <h2><strong>Privacy Policy</strong></h2>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div className="paragraph">
                                                    <div>
                                                        <p>Last updated: March 11, 2024</p>
                                                        <p>This Privacy Policy describes Our policies and procedures
                                                            on the collection, use and disclosure of Your
                                                            information when You use the Service and tells You about
                                                            Your privacy rights and how the law protects You.</p>
                                                        <p>We use Your Personal data to provide and improve the
                                                            Service. By using the Service, You agree to the
                                                            collection and use of information in accordance with
                                                            this Privacy Policy.</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-heading c-wrapper">

                                                <div
                                                    className="heading-5Q5OQ9UQ5C text-output cheading-5Q5OQ9UQ5C none noBorder radius0 none">
                                                    <div>
                                                        <h2><strong>Interpretation and Definitions</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-tne5bLCi_5 text-output csub-heading-tne5bLCi_5 none noBorder radius0 none">
                                                    <div>

                                                        <h2><strong>Interpretation</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-DGEUvFD7XQ text-output cparagraph-DGEUvFD7XQ none noBorder radius0 none">
                                                    <div>

                                                        <p>The words of which the initial letter is capitalized have
                                                            meanings defined under the following conditions. The
                                                            following definitions shall have the same meaning
                                                            regardless of whether they appear in singular or in
                                                            plural.</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-P9Ssfoj9wW text-output csub-heading-P9Ssfoj9wW none noBorder radius0 none">
                                                    <div>
                                                        <h3><strong>Definitions</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-w1zesqfuDF text-output cparagraph-w1zesqfuDF none noBorder radius0 none">
                                                    <div>

                                                        <p>For the purposes of this Privacy Policy:</p>
                                                        <p><strong>• Account </strong>: means a unique account
                                                            created for You to access our Service or parts of our
                                                            Service.</p>
                                                        <p><strong>• Affiliate </strong>: means an entity that
                                                            controls, is controlled by or is under common control
                                                            with a party, where "control" means ownership of 50% or
                                                            more of the shares, equity interest or other securities
                                                            entitled to vote for election of directors or other
                                                            managing authority.</p>
                                                        <p>Company: (referred to as either "the Company", "We", "Us"
                                                            or "Our" in this Agreement) refers to Extronnect, 30 N
                                                            Gould St, STE R Sheridan, WY 82801.</p>
                                                        <p><strong>• Cookies </strong>: are small files that are
                                                            placed on Your computer, mobile device or any other
                                                            device by a website, containing the details of Your
                                                            browsing history on that website among its many uses.
                                                        </p>
                                                        <p><strong>• Country </strong>: refers to Wyoming, United
                                                            States</p>
                                                        <p><strong>• Device </strong>: means any device that can
                                                            access the Service such as a computer, a cellphone or a
                                                            digital tablet.</p>
                                                        <p><strong>• Personal Data </strong>: is any information
                                                            that relates to an identified or identifiable
                                                            individual.</p>
                                                        <p><strong>• Service</strong>: refers to the Website.</p>
                                                        <p><strong>• Service Provider </strong>: means any natural
                                                            or legal person who processes the data on behalf of the
                                                            Company. It refers to third-party companies or
                                                            individuals employed by the Company to facilitate the
                                                            Service, to provide the Service on behalf of the
                                                            Company, to perform services related to the Service or
                                                            to assist the Company in analyzing how the Service is
                                                            used.</p>
                                                        <p><strong>• Third-party Social Media Service </strong>:
                                                            refers to any website or any social network website
                                                            through which a User can log in or create an account to
                                                            use the Service.</p>
                                                        <p><strong>• Usage Data </strong>: refers to data collected
                                                            automatically, either generated by the use of the
                                                            Service or from the Service infrastructure itself (for
                                                            example, the duration of a page visit).</p>
                                                        <p><strong>• Website </strong>: refers to Extronnect,
                                                            accessible from <Link
                                                                to="/">http://extronnect.com/</Link>
                                                        </p>
                                                        <p><strong>• You </strong>: means the individual accessing
                                                            or using the Service, or the company, or other legal
                                                            entity on behalf of which such individual is accessing
                                                            or using the Service, as applicable.</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-i-PoFDFbm1 text-output csub-heading-i-PoFDFbm1 none noBorder radius0 none">
                                                    <div>
                                                        <h2><strong>Collecting and Using Your Personal Data</strong>
                                                        </h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-G0OrUfWGhR text-output csub-heading-G0OrUfWGhR none noBorder radius0 none">
                                                    <div>
                                                        <h3><strong>Types of Data Collected</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-Vk1ODa55yq text-output csub-heading-Vk1ODa55yq none noBorder radius0 none">
                                                    <div>
                                                        <h4><br /><strong>• Personal Data</strong></h4>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-ufFPmBDyqG text-output cparagraph-ufFPmBDyqG none noBorder radius0 none">
                                                    <div>
                                                        <p><br />While using Our Service, We may ask You to provide Us
                                                            with certain personally identifiable information that
                                                            can be used to contact or identify You. Personally
                                                            identifiable information may include, but is not limited
                                                            to:</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-4OXDZ8rVBy text-output cparagraph-4OXDZ8rVBy none noBorder radius0 none">
                                                    <div>
                                                        <p>- Email address</p>
                                                        <p>-First name and last name</p>
                                                        <p>- Phone number</p>
                                                        <p>- Address, State, Province, ZIP/Postal code, City</p>
                                                        <p>- Usage Data</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-Ev5VT1NBar text-output csub-heading-Ev5VT1NBar none noBorder radius0 none">
                                                    <div>
                                                        <h4><br /><strong>• Usage Data</strong></h4>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-N32uWaN-Mx text-output cparagraph-N32uWaN-Mx none noBorder radius0 none">
                                                    <div>

                                                        <p>Usage Data is collected automatically when using the
                                                            Service.</p>
                                                        <p>Usage Data may include information such as Your Device's
                                                            Internet Protocol address (e.g. IP address), browser
                                                            type, browser version, the pages of our Service that You
                                                            visit, the time and date of Your visit, the time spent
                                                            on those pages, unique device identifiers and other
                                                            diagnostic data.</p>
                                                        <p>When You access the Service by or through a mobile
                                                            device, We may collect certain information
                                                            automatically, including, but not limited to, the type
                                                            of mobile device You use, Your mobile device unique ID,
                                                            the IP address of Your mobile device, Your mobile
                                                            operating system, the type of mobile Internet browser
                                                            You use, unique device identifiers and other diagnostic
                                                            data.</p>
                                                        <p>We may also collect information that Your browser sends
                                                            whenever You visit our Service or when You access the
                                                            Service by or through a mobile device.</p>
                                                        <h4><strong>Information from Third-Party Social Media
                                                            Services</strong></h4>
                                                        <p>The Company allows You to create an account and log in to
                                                            use the Service through the following Third-party Social
                                                            Media Services:</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-oMeaxU1PR1 text-output cparagraph-oMeaxU1PR1 none noBorder radius0 none">
                                                    <div>
                                                        <p>- Google</p>
                                                        <p>- Facebook</p>
                                                        <p>- Instagram</p>
                                                        <p>- Twitter</p>
                                                        <p>- LinkedIn</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-8TUeI9_gRV text-output cparagraph-8TUeI9_gRV none noBorder radius0 none">
                                                    <div>

                                                        <p>If You decide to register through or otherwise grant us
                                                            access to a Third-Party Social Media Service, We may
                                                            collect Personal data that is already associated with
                                                            Your Third-Party Social Media Service's account, such as
                                                            Your name, Your email address, Your activities or Your
                                                            contact list associated with that account.</p>
                                                        <p>You may also have the option of sharing additional
                                                            information with the Company through Your Third-Party
                                                            Social Media Service's account. If You choose to provide
                                                            such information and Personal Data, during registration
                                                            or otherwise, You are giving the Company permission to
                                                            use, share, and store it in a manner consistent with
                                                            this Privacy Policy.</p>
                                                        <h4><strong>Tracking Technologies and Cookies</strong></h4>
                                                        <p>We use Cookies and similar tracking technologies to track
                                                            the activity on Our Service and store certain
                                                            information. Tracking technologies used are beacons,
                                                            tags, and scripts to collect and track information and
                                                            to improve and analyze Our Service. The technologies We
                                                            use may include:</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-KPUagw1u-b text-output cparagraph-KPUagw1u-b none noBorder radius0 none">
                                                    <div>
                                                        <p>- <strong>Cookies or Browser Cookies. </strong>A cookie
                                                            is a small file placed on Your Device. You can instruct
                                                            Your browser to refuse all Cookies or to indicate when a
                                                            Cookie is being sent. However, if You do not accept
                                                            Cookies, You may not be able to use some parts of our
                                                            Service. Unless you have adjusted Your browser setting
                                                            so that it will refuse Cookies, our Service may use
                                                            Cookies.</p>

                                                        <p>- <strong>Web Beacons.</strong> Certain sections of our
                                                            Service and our emails may contain small electronic
                                                            files known as web beacons (also referred to as clear
                                                            gifs, pixel tags, and single-pixel gifs) that permit the
                                                            Company, for example, to count users who have visited
                                                            those pages or opened an email and for other related
                                                            website statistics (for example, recording the
                                                            popularity of a certain section and verifying system and
                                                            server integrity).</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-yyc6PC-qd2 text-output cparagraph-yyc6PC-qd2 none noBorder radius0 none">
                                                    <div>

                                                        
                                                        <p>Cookies can be "Persistent" or "Session" Cookies.
                                                            Persistent Cookies remain on Your personal computer or
                                                            mobile device when You go offline, while Session Cookies
                                                            are deleted as soon as You close Your web browser. You
                                                            can learn more about cookies on TermsFeed website
                                                            article.</p>
                                                        <p>We use both Session and Persistent Cookies for the
                                                            purposes set out below:</p>
                                                        
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-LsCepMKS-B text-output cparagraph-LsCepMKS-B none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <p><strong>Cookies Policy / Notice Acceptance
                                                            Cookies</strong></p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-t1A1VFJCfC text-output cparagraph-t1A1VFJCfC none noBorder radius0 none">
                                                    <div>
                                                        <p><strong>- Type </strong>: Session Cookies</p>
                                                        <p><strong>- Administered by</strong> : Us</p>
                                                        <p><strong>- Purpose</strong> : These Cookies are essential
                                                            to provide You with services available through the
                                                            Website and to enable You to use some of its features.
                                                            They help to authenticate users and prevent fraudulent
                                                            use of user accounts. Without these Cookies, the
                                                            services that You have asked for cannot be provided, and
                                                            We only use these Cookies to provide You with those
                                                            services.</p>

                                                        <p><strong>- Type</strong>: Persistent Cookies</p>
                                                        <p><strong>- Administered by</strong>: Us</p>
                                                        <p><strong>- Purpose</strong>: These Cookies allow us to
                                                            remember choices You make when You use the Website, such
                                                            as remembering your login details or language
                                                            preference. The purpose of these Cookies is to provide
                                                            You with a more personal experience and to avoid You
                                                            having to re-enter your preferences every time You use
                                                            the Website.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-Fk0yUekc-j text-output cparagraph-Fk0yUekc-j none noBorder radius0 none">
                                                    <div>
                                                        <p><strong><br />Necessary / Essential Cookies</strong></p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-WyO-DgGCMM text-output cparagraph-WyO-DgGCMM none noBorder radius0 none">
                                                    <div>
                                                        <p><strong>- Type</strong>: Persistent Cookies</p>
                                                        <p><strong>- Administered by</strong>: Us</p>
                                                        <p><strong>- Purpose</strong>: These Cookies identify if
                                                            users have accepted the use of cookies on the Website
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-nFqxjSPrU4 text-output cparagraph-nFqxjSPrU4 none noBorder radius0 none">
                                                    <div>
                                                        <p><strong><br /></strong></p>
                                                        <p><strong>Functionality Cookies</strong></p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-J6vWHHj0Cb text-output cparagraph-J6vWHHj0Cb none noBorder radius0 none">
                                                    <div>
                                                        <p><strong>- Type</strong>: Persistent Cookies</p>
                                                        <p><strong>- Administered by</strong>: Us</p>
                                                        <p><strong>- Purpose</strong>: These Cookies allow us to
                                                            remember choices You make when You use the Website, such
                                                            as remembering your login details or language
                                                            preference. The purpose of these Cookies is to provide
                                                            You with a more personal experience and to avoid You
                                                            having to re-enter your preferences every time You use
                                                            the Website.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-hjI6WV7ld7 text-output cparagraph-hjI6WV7ld7 none noBorder radius0 none">
                                                    <div>

                                                        <p>For more information about the cookies we use and your
                                                            choices regarding cookies, please visit our Cookies
                                                            Policy or the Cookies section of our Privacy Policy.</p>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph--ATNV5BX1B text-output cparagraph--ATNV5BX1B none noBorder radius0 none">
                                                    <div>

                                                        <h3><strong>Use of Your Personal Data</strong></h3>
                                                    </div>
                                                </div>

                                            </div>


                                            
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-_InfnXn3tF text-output cparagraph-_InfnXn3tF none noBorder radius0 none">
                                                    <div>

                                                        <p>The Company may use Personal Data for the following
                                                            purposes:</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-vGRV2oTkpa text-output cparagraph-vGRV2oTkpa none noBorder radius0 none">
                                                    <div>

                                                        <p><strong>-To provide and maintain our Service </strong>:
                                                            including to monitor the usage of our Service<a target="_blank"
                                                                rel="noopener noreferrer nofollow" href="http://Service.To">.</a></p>
                                                        <p><strong>-To manage Your Account </strong>: to manage Your
                                                            registration as a user of the Service. The Personal Data
                                                            You provide can give You access to different
                                                            functionalities of the Service that are available to You
                                                            as a registered user.</p>
                                                        <p><strong>-For the performance of a contract </strong>: the
                                                            development, compliance and undertaking of the purchase
                                                            contract for the products, items or services You have
                                                            purchased or of any other contract with Us through the
                                                            Service.</p>
                                                        <p><strong>-To contact You </strong>: To contact You by
                                                            email, telephone calls, SMS, or other equivalent forms
                                                            of electronic communication, such as a mobile
                                                            application's push notifications regarding updates or
                                                            informative communications related to the
                                                            functionalities, products or contracted services,
                                                            including the security updates, when necessary or
                                                            reasonable for their implementation.</p>
                                                        <p>-<strong>To provide You : </strong>with news, special
                                                            offers and general information about other goods,
                                                            services and events which we offer that are similar to
                                                            those that you have already purchased or enquired about
                                                            unless You have opted not to receive such information.
                                                        </p>
                                                        <p><strong>-To manage Your requests : </strong>To attend and
                                                            manage Your requests to Us. </p>
                                                        <p><strong>-For business transfers </strong>: We may use
                                                            Your information to evaluate or conduct a merger,
                                                            divestiture, restructuring, reorganization, dissolution,
                                                            or other sale or transfer of some or all of Our assets,
                                                            whether as a going concern or as part of bankruptcy,
                                                            liquidation, or similar proceeding, in which Personal
                                                            Data held by Us about our Service users is among the
                                                            assets transferred.</p>
                                                        <p><strong>For other purposes </strong>: We may use Your
                                                            information for other purposes, such as data analysis,
                                                            identifying usage trends, determining the effectiveness
                                                            of our promotional campaigns and to evaluate and improve
                                                            our Service, products, services, marketing and your
                                                            experience.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-8eQOQM8LTf text-output cparagraph-8eQOQM8LTf none noBorder radius0 none">
                                                    <div>
                                                        <p><br />We not share Your personal information in the
                                                            following situations:</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph--DiElkZALn text-output cparagraph--DiElkZALn none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <p><strong>-With Service Providers </strong>: We  don't share
                                                            Your personal information with Service Providers to
                                                            monitor and analyze the use of our Service, to contact
                                                            You.</p>
                                                            <p><strong>-Mobile Information Usage
                                                            </strong>: No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. Information sharing to subcontractors in support services, such as customer service is permitted. All other use case categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>

                                                        <p><strong>-For business transfers </strong>: We don't share
                                                            or transfer Your personal information in connection
                                                            with, or during negotiations of, any merger, sale of
                                                            Company assets, financing, or acquisition of all or a
                                                            portion of Our business to another company.</p>
                                                        <p><strong>-With Affiliates </strong>: We don't share Your
                                                            information with Our affiliates, in which case we will
                                                            require those affiliates to honor this Privacy Policy.
                                                            Affiliates include Our parent company and any other
                                                            subsidiaries, joint venture partners or other companies
                                                            that We control or that are under common control with
                                                            Us.</p>
                                                        <p><strong>-With business partners </strong>: We don't share
                                                            Your information with Our business partners to offer You
                                                            certain products, services or promotions.</p>
                                                        <p><strong>-With other users </strong>: when You share
                                                            personal information or otherwise interact in the public
                                                            areas with other users, such information may be viewed
                                                            by all users and may be publicly distributed outside. If
                                                            You interact with other users or register through a
                                                            Third-Party Social Media Service, Your contacts on the
                                                            Third-Party Social Media Service may see Your name,
                                                            profile, pictures and description of Your activity.
                                                            Similarly, other users will be able to view descriptions
                                                            of Your activity, communicate with You and view Your
                                                            profile.</p>
                                                        <p><strong>-With Your consent </strong>: We may disclose
                                                            Your personal information for any other purpose with
                                                            Your consent.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-rw6cPX8w5H text-output csub-heading-rw6cPX8w5H none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h3><strong>Retention of Your Personal Data</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-IF5fHzP3g_ text-output cparagraph-IF5fHzP3g_ none noBorder radius0 none">
                                                    <div>

                                                        <p>The Company will retain Your Personal Data only for as
                                                            long as is necessary for the purposes set out in this
                                                            Privacy Policy. We will retain and use Your Personal
                                                            Data to the extent necessary to comply with our legal
                                                            obligations (for example, if we are required to retain
                                                            your data to comply with applicable laws), resolve
                                                            disputes, and enforce our legal agreements and policies.
                                                        </p>
                                                        <p>The Company will also retain Usage Data for internal
                                                            analysis purposes. Usage Data is generally retained for
                                                            a shorter period of time, except when this data is used
                                                            to strengthen the security or to improve the
                                                            functionality of Our Service, or We are legally
                                                            obligated to retain this data for longer time periods.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-eM8ll6k097 text-output csub-heading-eM8ll6k097 none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h3><strong>Transfer of Your Personal Data</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-d9ELeU5uoo text-output cparagraph-d9ELeU5uoo none noBorder radius0 none">
                                                    <div>

                                                        <p>Your information, including Personal Data, is processed
                                                            at the Company's operating offices and in any other
                                                            places where the parties involved in the processing are
                                                            located. It means that this information may be
                                                            transferred to — and maintained on — computers located
                                                            outside of Your state, province, country or other
                                                            governmental jurisdiction where the data protection laws
                                                            may differ than those from Your jurisdiction.</p>
                                                        <p>Your consent to this Privacy Policy followed by Your
                                                            submission of such information represents Your agreement
                                                            to that transfer.</p>
                                                        <p>The Company will take all steps reasonably necessary to
                                                            ensure that Your data is treated securely and in
                                                            accordance with this Privacy Policy and no transfer of
                                                            Your Personal Data will take place to an organization or
                                                            a country unless there are adequate controls in place
                                                            including the security of Your data and other personal
                                                            information.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-z1sun6dJ1v text-output csub-heading-z1sun6dJ1v none noBorder radius0 none">
                                                    <div>
                                                        

                                                        <h3><strong>Delete Your Personal Data</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-F-RWvzwNlw text-output cparagraph-F-RWvzwNlw none noBorder radius0 none">
                                                    <div>

                                                        <p>You have the right to delete or request that We assist in
                                                            deleting the Personal Data that We have collected about
                                                            You.</p>
                                                        <p>Our Service may give You the ability to delete certain
                                                            information about You from within the Service.</p>
                                                        <p>You may update, amend, or delete Your information at any
                                                            time by signing in to Your Account, if you have one, and
                                                            visiting the account settings section that allows you to
                                                            manage Your personal information. You may also contact
                                                            Us to request access to, correct, or delete any personal
                                                            information that You have provided to Us.</p>
                                                        <p>Please note, however, that We may need to retain certain
                                                            information when we have a legal obligation or lawful
                                                            basis to do so.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-a-fXI85wG8 text-output csub-heading-a-fXI85wG8 none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h3><strong>Disclosure of Your Personal Data</strong></h3>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-PiPQne2V6B text-output cparagraph-PiPQne2V6B none noBorder radius0 none">
                                                    <div>

                                                        <h4><strong>Business Transactions</strong></h4>
                                                        <p>If the Company is involved in a merger, acquisition or
                                                            asset sale, Your Personal Data may be transferred. We
                                                            will provide notice before Your Personal Data is
                                                            transferred and becomes subject to a different Privacy
                                                            Policy.</p>
                                                        <h4><strong>Law enforcement</strong></h4>
                                                        <p>Under certain circumstances, the Company may be required
                                                            to disclose Your Personal Data if required to do so by
                                                            law or in response to valid requests by public
                                                            authorities (e.g. a court or a government agency).</p>
                                                        <h4><strong>Other legal requirements</strong></h4>
                                                        <p>The Company may disclose Your Personal Data in the good
                                                            faith belief that such action is necessary to:</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-ojTLyHxQKR text-output cparagraph-ojTLyHxQKR none noBorder radius0 none">
                                                    <div>

                                                        <p>-Comply with a legal obligation</p>
                                                        <p>-Protect and defend the rights or property of the Company
                                                        </p>
                                                        <p>-Prevent or investigate possible wrongdoing in connection
                                                            with the Service</p>
                                                        <p>-Protect the personal safety of Users of the Service or
                                                            the public</p>
                                                        <p>-Protect against legal liability</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-1x-UwxifmV text-output cparagraph-1x-UwxifmV none noBorder radius0 none">
                                                    <div>

                                                        <h3><strong>Security of Your Personal Data</strong></h3>
                                                        <p>The security of Your Personal Data is important to Us,
                                                            but remember that no method of transmission over the
                                                            Internet, or method of electronic storage is 100%
                                                            secure. While We strive to use commercially acceptable
                                                            means to protect Your Personal Data, We cannot guarantee
                                                            its absolute security.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-zZWxmEePyu text-output csub-heading-zZWxmEePyu none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h2><strong>Children's Privacy</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-Pm8V4IKfc- text-output cparagraph-Pm8V4IKfc- none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <p>Our Service does not address anyone under the age of 13.
                                                            We do not knowingly collect personally identifiable
                                                            information from anyone under the age of 13. If You are
                                                            a parent or guardian and You are aware that Your child
                                                            has provided Us with Personal Data, please contact Us.
                                                            If We become aware that We have collected Personal Data
                                                            from anyone under the age of 13 without verification of
                                                            parental consent, We take steps to remove that
                                                            information from Our servers.</p>
                                                        <p>If We need to rely on consent as a legal basis for
                                                            processing Your information and Your country requires
                                                            consent from a parent, We may require Your parent's
                                                            consent before We collect and use that information.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div
                                                    className="sub-heading-U1pADmJoIG text-output csub-heading-U1pADmJoIG none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h2><strong>Links to Other Websites</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-HTmtW6EFk0 text-output cparagraph-HTmtW6EFk0 none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <p>Our Service may contain links to other websites that are
                                                            not operated by Us. If You click on a third party link,
                                                            You will be directed to that third party's site. We
                                                            strongly advise You to review the Privacy Policy of
                                                            every site You visit.</p>
                                                        <p>We have no control over and assume no responsibility for
                                                            the content, privacy policies or practices of any third
                                                            party sites or services.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-heading c-wrapper">

                                                <div
                                                    className="heading-w2Q5jy5gR4 text-output cheading-w2Q5jy5gR4 none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <h2><strong>Changes to this Privacy Policy</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="paragraph-rwques3_kX text-output cparagraph-rwques3_kX none noBorder radius0 none">
                                                    <div>
                                                        <br />
                                                        <p>We may update Our Privacy Policy from time to time. We
                                                            will notify You of any changes by posting the new
                                                            Privacy Policy on this page.</p>
                                                        <p>We will let You know via email and/or a prominent notice
                                                            on Our Service, prior to the change becoming effective
                                                            and update the "Last updated" date at the top of this
                                                            Privacy Policy.</p>
                                                        <p>You are advised to review this Privacy Policy
                                                            periodically for any changes. Changes to this Privacy
                                                            Policy are effective when they are posted on this page.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-sub-heading c-wrapper">

                                                <div>
                                                    <div>

                                                        <h2><strong>Contact Us</strong></h2>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="c-paragraph c-wrapper">

                                                <div
                                                    className="copyright">
                                                    <div>


                                                        <p>If you have any questions about this Privacy Policy, You
                                                            can contact us:</p>
                                                        <p>By email:<strong> </strong><a target="_blank" rel="noreferrer"
                                                            href="mailto:Support@extronnect.com"><strong>Support@extronnect.com</strong></a>
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <Footer />
        </>
    );
}

export default Policy;
