import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
// import {  Autoplay, EffectFade } from "swiper";
import Footer from "./Footer";
import { FaStar } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";

import "animate.css";
import "swiper/css/effect-fade";
import "animate.css";
import FormComponent from "./FormComponent";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { Navigation } from "swiper/modules";
import { HashLink } from "react-router-hash-link";
import { db } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";

const Preloader = () => <div class="loader"></div>;
function Home() {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    console.log(event.target.value);
    setFormData({
      [event.target.value]: true,
    });
  };

  const handleSubmitNews = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    setDoc(doc(db, "newsLetter", "emails"), formData, {
      merge: true,
    });
    const emailHtml = `Thank you for subscribing to the Extronnect newsletter! You'll receive our latest updates and insights directly to your inbox. Welcome aboard!<br/><br/>`;
    const signatureHtml = `<font color="#888888">
    <span class="gmail_signature_prefix">-- </span><br>
    <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
        <div dir="ltr">
            <table class="signature-table" width="414" cellpadding="0" cellspacing="0" border="0">
                <tbody>
                    <tr>
                        <td valign="top" class="signature-cell">
                            <table border="0" cellpadding="0" cellspacing="0" width="414" class="inner-table">
                                <tbody>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="name-title"><font face="arial black, sans-serif">Samir H</font></span>
                                        </td>
                                        <td class="no-padding"></td>
                                    </tr>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="position-title"><font face="arial black, sans-serif">Client Success Manager<br><br></font></span>
                                        </td>
                                        <td align="right" width="170" class="no-padding">
                                            <p dir="ltr" class="social-links">
                                                <span>
                                                    <a href="http://extronnect.com/" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/ERQye9fHlkGU5k3qeAOaIPlXt6WEXXaU3Qk6sX-RPc989aGEznY7hiAxlQrZZy4jbAFXGrMQpBM7XooSYiCJQoTXjmwx2feUNpI2IMZAFp4rcsQCUmyJTDeeYB7wXVKx2ajwEOROluc4SDSAovfxy44" width="23" height="23" alt="Website" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://www.instagram.com/extronnect/" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/RBHXzA6EarsjkC28LnCKIOX46gSouRWQvToQulLKq_T8amnmvJz3GO1Pd5GML1bps41dLRSlJe8y3UR6olRNRWBBzflLsAPtb2KI9n6PynABhc6WXrH3CrSimE6b-7cn4ntkAr6EnTkr2i0Qw9QQr0w" width="23" height="23" alt="Instagram" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://wa.me/15619225376" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/Rgf8cTDfganYaSmVeRG8an1XXfBmeubjqi1eCPjKsprqPPgN6A-isjGF-TvzNNacha4ut_sMzFYXo3DbOEMb9SIcp4vA1kKnvykcTww8iV_wh2L6GsyVb9aTQiZO5e7m-2hESYGqdtbnOFF8WJBWuUg" width="23" height="23" alt="WhatsApp" class="icon">
                                                    </a>&nbsp;
                                                </span>
                                                <span>
                                                    <a href="https://www.linkedin.com/company/100955777" target="_blank">
                                                        <img src="https://lh7-us.googleusercontent.com/0554XXBDxwmR3G1JA7ijlqWufEnXfK_l1LUpuqg-eydQVjai3t0GXbVp1xrqfvKH1g1thL1EzqjUpMqvwITbTb7ndmCv1125NqDuBN45wsCQkCB17bxXIpAEfbCMv_G2WQwpAqFp6vIBmNo3uqgzKMI" width="23" height="23" alt="LinkedIn" class="icon">
                                                    </a>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-padding">
                                            <span class="contact-info">
                                                <span class="icon-cell">
                                                    <img src="https://lh7-us.googleusercontent.com/qv5uavYFMPAfRw6bWUk8GYOV-ngLGdu4ndYfyql8dq8Cogw2Jfgm4IZhbUIyj50D0kNLCA-J8mF_YCkw5RrKm-6p3cQog0MTiVkR6WE_EK5fcMgLP9ufvtzS_N87-tNRUJuucuG9sNvShAplf9oDnW4" width="19" height="19" class="contact-icon">
                                                </span>
                                                <font face="arial black, sans-serif">(310) 601-8347</font>
                                                <br>
                                                <span class="icon-cell">
                                                    <img src="https://lh7-us.googleusercontent.com/d6sEan4jectEq-6hIecRokXTA4nHSmFDVk0v71K5EXwxoepvfnMyxoNfUhrrTlVOLbssr81WHwRaBO-IhvFHOrD2vkl0gsB7nmxPznFc_YGxFTRiwwvhQMgxlr4i3mPJ2gz9SSNdsbZFLQMRs0JZzQo" width="19" height="19" class="contact-icon">
                                                </span>&nbsp;
                                                <a href="mailto:support@extronnect.com" class="email-link">Support@extronnect.com</a>&nbsp; &nbsp; &nbsp;
                                            </span>
                                        </td>
                                        <td valign="bottom" align="right" class="no-padding"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td class="banner-cell">
                            <a href="https://extronnect.com/" target="_blank">
                                <img border="0" alt="Banner" width="414" src="https://ci3.googleusercontent.com/meips/ADKq_Nao5PJGW-cu5EO2By_8Ke9Dcl1rL90Pfc7dZ7nzBIH9qx9rk_HYSLsB2oqY6rLLWq_UIJ-n=s0-d-e1-ft#https://i.imgur.com/u495xLU.png" class="banner-image">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="disclaimer-cell">
                            The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</font>
`;
    const fullHtml = emailHtml + signatureHtml;
    await addDoc(collection(db, "mail"), {
      to: Object.keys(formData)[0],
      message: {
        subject: "Thank You For Subscribe",
        html: fullHtml,
      },
    });
    setIsSubmitting(false);
    document.getElementById("newsForm").reset();
  };

  return (
    <div className="homeremain">
      <Helmet>
        <title>Extronnect | Home - Your Gateway to Business Success </title>
      </Helmet>
      <section className="homeSec1">
      <div className="overlay"></div>
      <img
                className="animate__animated animate__fadeInBottomLeft img-banner"
                src="images/cover.png"
                alt="cover"
              />
        <Container>
          <Row>
            <Col lg="8" md="8" sm="12">
              <div>
                <h1 className="animate__animated animate__fadeInLeft ">
                  <strong>Welcome to Extronnect</strong>
                </h1>
                <p className="animate__animated animate__fadeInLeft animate__delay-1s">
                  Where Solutions Meet Innovation
                </p>
                <HashLink to="/#Inquiry">
                  <button className="button-home wow fadeInUpBig">
                    Submit An Inquiry
                  </button>
                </HashLink>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" className="homeImg">
           
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec2" id="Services">
        <Container>
          <h2 className="animate__animated animate__fadeInUp">
            Services We Can Help With
          </h2>
          <Row>
            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Business Administration</h3>
                    <img
                      src="images/services/op1/part2/Operationsmanagement.png"
                      alt="Operations management"
                    />
                    <p>
                      Streamline your operations and boost productivity with our
                      tailored solutions.
                    </p>
                    <Link to="/BusinessAdministration">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Customer Support</h3>
                    <img
                      src="images/services/op1/CustomerSupport.png"
                      alt="Customer Support"
                    />
                    <p>
                      Deliver exceptional service with our seamless customer
                      support expertise.
                    </p>
                    <Link to="/CustomerSupport">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Software Development</h3>
                    <img
                      src="images/services/op1/part2/Websitecreation.png"
                      alt="Website creation"
                    />
                    <p>
                      Building seamless digital experiences tailored to your
                      brand
                    </p>
                    <Link to="/SoftwareDevelopment">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Digital Marketing</h3>
                    <img
                      src="images/services/op1/part2/Marketingcmapaigns.png"
                      alt="Marketing cmapaigns"
                    />
                    <p>
                      Drive results and captivate your audience with our
                      innovative Digital Marketing campaigns.
                    </p>
                    <Link to="/Marketing">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Creative Services</h3>
                    <img
                      src="images/services/op1/part2/graphicdesign.png"
                      alt="graphic design"
                    />
                    <p>
                      Unleash your brand's creativity and captivate your
                      audience with our cutting-edge design expertise.
                    </p>
                    <Link to="/CreativeServices">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div>
                  <div className="hoverdetail wow fadeInUp">
                    <h3>Automated Sales</h3>
                    <img
                      src="images/services/op1/OutsourcingSolutions.png"
                      alt="Outsourcing Solutions"
                    />
                    <p>
                      Elevate your business game with our expert negotiation and
                      management solutions.
                    </p>
                    <Link to="/SalesSolutions">
                      <button className="button-service">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec3" id="About">
        <div className="borderDiv"></div>
        <h2>About Us</h2>

        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInLeft">
                <h5>
                  With a collective experience spanning over almost 5 years
                  across various industries Extronnect stands as your premier
                  destination for comprehensive marketing and operational
                  solutions. Our diverse background encompasses expertise in
                  Marketing ,Business Development , Tech Development ,Automated Sales and
                  Customer support At Extronnect, we seamlessly integrate these
                  specialised domains into a unified platform, offering a
                  holistic approach to address all your business needs.
                </h5>
                <Link to="/About">
                  <button className="button-service">
                    More Info <FaArrowRightLong />
                  </button>
                </Link>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInRight">
                <img
                  src="images/vectors/AboutUs.png"
                  alt="About Us"
                  className="mainimg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec3" id="Strategy">
        <div className="borderDiv"></div>
        <h2>Strategy Call</h2>
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft">
                <img
                  src="images/vectors/StrategyCall.png"
                  alt="Strategy Call"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp">
                <h4>
                  At <span>Extronnect</span>, we're committed to helping you
                  achieve your business goals. Schedule a
                  <span className="highlight"> FREE</span> strategy call with our
                  experts to discuss how we can tailor our services to meet your
                  unique needs.
                </h4>
                <ul className="wow fadeInUp">
                  <li>
                    <p>
                      <FaCircle /> Personalized advice tailored to your business
                    </p>
                  </li>
                  <li>
                    <p>
                      <FaCircle /> Insights into industry best practices and
                      trends
                    </p>
                  </li>
                  <li>
                    <p>
                      <FaCircle /> Customized solutions to address your specific
                      challenges
                    </p>
                  </li>
                  <li>
                    <p>
                      <FaCircle /> Opportunity to explore how Extronnect can
                      support your growth journey
                    </p>
                  </li>
                </ul>
                <Link to="/ScheduleCall" className="wow fadeInUp">
                  <button className="button-service">
                    Schedule Call
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec3 news">
        <div className="borderDiv"></div>

        <h2 className="wow fadeInUp">Newsletter</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12" className="colnews">
              <div className="wow fadeInUp">
                <h4>
                  Stay informed and ahead of the curve with our newsletter!
                  Subscribe today to receive valuable insights, exclusive
                  offers, and updates on the latest trends in business
                  solutions.
                </h4>
                <h6>Benefits</h6>
                <h4>
                  Receive industry insights and expert advice directly to your
                  inbox. Be the first to know about new services, promotions,
                  and special offers.
                </h4>
                <br />

                <h4>
                  Stay informed about upcoming events, webinars, and workshops
                  hosted by <span>Extronnect</span>.
                </h4>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInRight">
                <img
                  src="images/vectors/Newsletter.png"
                  alt="Newsletter"
                  className="mainimg"
                />
              </div>
              <form
                className="homeSecForm"
                onSubmit={handleSubmitNews}
                id="newsForm"
              >
                <input
                  type="email"
                  placeholder="Email*"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  required
                />
                <button> {isSubmitting ? <Preloader /> : "Subscribe"}</button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec4" id="Clients">
        <div className="borderDiv"></div>
        <Container>
          <h2 className="wow fadeInUp">What Our Clients Say</h2>
          <h3 className="wow fadeInUp">
            At <span>Extronnect</span> , we believe in the power of customer
            feedback. Here's what some of our clients have to say about their
            experiences working with us.
          </h3>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            slidesPerGroup={1}
            className="mySwiper fadeInUp wow"
            data-wow-duration="0.5s"
            loop
            navigation={true}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <div>
                <img
                  src="images/0d22fd6e46a30758f5f777514662a564_1200_80.webp"
                  alt="review"
                />
                <h4>
                  "<span>Extronnect</span> transformed our business with their
                  exceptional virtual assistant services. Our productivity has
                  skyrocketed, thanks to their efficient support." "The
                  telemarketing solutions provided by <span>Extronnect </span>
                  helped us generate quality leads and boost our Automated Sales
                  significantly."
                </h4>
                <div className="iconDiv">
                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />
                </div>
                <p>Michael S - New York, NY</p>
                <p className="name">Impressive Results</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="images/photo1715887579.jpeg" alt="review" />
                <h4>
                  "I hired <span>Extronnect</span> and was impressed with their
                  diligence, enthusiasm and willingness to go above and beyond.
                  I plan to use their services again."
                </h4>
                <div className="iconDiv">
                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />
                </div>
                <p>Henry W - Vero Beach, FL</p>
                <p className="name">Great Service</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="images/photo1719290732.jpeg"
                  alt="review"
                />
                <h4>

                  "<span>Extronnect</span> team has been absolutely amazing to work with. They have helped me and my business succeed and thrive! They are always there to help, their prices are fair, and their work is awesome!"
                </h4>
                <div className="iconDiv">
                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />

                  <FaStar />
                </div>
                <p>Eric L - Millersville, MD</p>
              </div>
            </SwiperSlide>
          </Swiper>
          <h6>
            Ready to experience the <span>Extronnect</span> difference? Contact
            us today to learn more about our services or leave your feedback
            below.
          </h6>
        </Container>
      </section>
      <br />
      <FormComponent />
      <Footer />
    </div>
  );
}

export default Home;
